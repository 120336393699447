import React, { FC } from "react"


type TableProps = {
  thead: string[],
  tbody: string[][]
}

const Table: FC<TableProps> = ({
  thead,
  tbody
}: TableProps) => {
  return (
    <table className="table-auto" style={{ fontFamily: "Chilanka" }}>
      <thead>
        <tr className="border border-gray-900">
          {
            thead.map((item, index) => {
              return(
                <th
                  key={index}
                  className="px-2 py-2 text-center border border-gray-900 align-top leading-tight"
                >
                  {item}
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          tbody.map((row, index) => {
            return(
              <tr
                key={index}
                className="border border-gray-900"
              >
                {
                  row.map((item, index) => {
                    return(
                      <td
                        key={index}
                        className="px-4 py-2 border border-gray-900"
                      >
                        {item}
                      </td>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default Table
