import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import Table from "~/components/Table/table";

const tHead = [
  "",
  "Prescription medicine",
  "Over-the-counter medicine",
  "Dietary supplement "
]

const tBody = [
  ["Regulations", "", "", ""],
  ["Scientific evidence", "", "", ""],
  ["Potential risks", "", "", ""]
]

const Page = () => {
  return (
    <LessonLayout
      current_step={12}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 12</H2>

        <p>In this lesson, you have learned about prescription drugs, OTC drugs, and dietary supplements.</p>
        <p>Draw a chart like the following in your science notebook.</p>
        <div className="my-4">
          <Table
            thead={tHead}
            tbody={tBody}
          />
        </div>
        <p>Rank each type of product for each category. For example, which product (prescription medicine, OTC medicine, or dietary supplement) is most highly regulated? Give that product a 1. Give the product that is least regulated a 3. Give the remaining product a 2.</p>
        <p>Continue for the other categories using a 1 for the highest level and 3 for the lowest. Be prepared to explain your rankings.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

